<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.school_id"
        placeholder="请选择学校"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in schools"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" @click="getList">
        查找
      </el-button>
    </div>

    <div class="color_tip">
      代课
      <div class="daike"></div>
      调课
      <div class="tiaoke"></div>
    </div>
    <div class="calendar">
      <div class="calendar_top">
        <div class="c_top_left">{{ year }}</div>
        <div class="c_top_right">
          <el-date-picker
            v-model="value1"
            type="date"
            placeholder="选择日期"
            @change="pickerChange"
            style="margin-right: 20px"
          >
          </el-date-picker>
          <el-button-group>
            <el-button type="plain" @click="getTime(2)"> 上周 </el-button>
            <el-button type="plain" @click="getTime(0)"> 今天 </el-button>
            <el-button type="plain" @click="getTime(1)"> 下周 </el-button>
          </el-button-group>
        </div>
      </div>
      <el-divider style="margin: 10px 0"></el-divider>
      <div class="calendar_centont">
        <div class="calendar_c_top">
          <div style="margin-top: 28px">
            <div v-for="jj in classKnob" :key="jj.id" class="broadside">
              {{ jj.name }}
            </div>
          </div>
          <div v-for="i in week" :key="i.id" class="calendar_item-top">
            <div style="margin-bottom: 10px">
              {{ i.name }}&nbsp;&nbsp;&nbsp;{{ i.data }}
            </div>
            <div
              class="calendar_item-centont"
              v-for="kk in classKnob"
              :key="kk.id"
            >
              <div
                v-for="(item, key) in events"
                :key="key"
                v-if="kk.name == item.class_time_config.name"
              >
                <div v-if="i.time == item.date" class="calendar_tag">
                  <!-- <p>课节:{{ item.class_time_config.name }}</p> -->
                  <p>课程名称:{{ item.class_course.school_course.name }}</p>
                  <p>上课班级:{{ item.class_course.classes.name }}</p>
                  <p>上课教师:{{ item.class_course.teacher.name }}</p>
                  上课地点:{{ item.classroom }}
                  <p>课堂备注:{{ item.class_requirements }}</p>
                  <div>
                    <el-button
                      type="warning"
                      size="mini"
                      style="margin-top: 5px"
                      @click="batchEdit(item)"
                    >
                      编辑备注
                    </el-button>
                  </div>
                  <!-- <el-dropdown @command="handleCommand($event, item)" v-if="item.is_me">
                    <span class="el-dropdown-link">
                      <i class="el-icon-s-tools el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" :split-button="true">
                      <el-dropdown-item command="1">代课</el-dropdown-item>
                      <el-dropdown-item command="2">调课</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="代课"
      :visible.sync="substituteCorrect"
      :close-on-click-modal="false"
    >
      <el-form
        ref="substitute"
        :rules="substituteRules"
        :model="substitute"
        label-width="120px"
      >
        <el-form-item label="上课教师" prop="core">
          <el-select
            v-model="form.teacher_id"
            placeholder="请选择教师"
            clearable
            style="width: 150px; margin-left: 10px"
            class="filter-item"
          >
            <el-option
              v-for="item in teachers1"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="substituteCorrect = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="调课"
      :visible.sync="adjustment"
      :close-on-click-modal="false"
    >
      <el-form
        ref="substitute"
        :rules="substituteRules"
        :model="substitute"
        label-width="120px"
      >
        <el-form-item label="日期" prop="core">
          <el-date-picker
            v-model="listQuery2.date"
            type="date"
            placeholder="选择月"
            style="margin-left: 10px"
            value-format="yyyy-MM-dd"
            @change="change"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="上课教师" prop="core">
          <span v-for="v in schedules" :key="v.id">
            <el-radio v-model="form2.schedule_id" :label="v.id"
              >{{ v.class_time_config.name }}
              {{ v.class_course.school_course.name }}
              {{ v.class_course.teacher.name }}</el-radio
            ></span
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adjustment = false">取消</el-button>
        <el-button type="primary" @click="saveData1" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改课堂备注"
      :visible.sync="editDialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <div>
        <el-input
          v-model="edit.class_requirements"
          type="textarea"
          style="width: 90%"
          :rows="4"
          placeholder="请输入课堂备注"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatTimeToStr } from "@/utils/data.js";
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        school_id: null,
        school_year: null,
        semester: null,
        department: null,
        grade: null,
        sclass: null,
        teacher: null,
        year: null,
        month: null,
        start_at: null,
        end_at: null,
      },
      thisWeek: [],
      week: [],
      classKnob: [
        { id: 1, name: "第1节" },
        { id: 2, name: "第2节" },
        { id: 3, name: "第3节" },
        { id: 4, name: "第4节" },
        { id: 5, name: "第5节" },
        { id: 6, name: "第6节" },
        { id: 7, name: "第7节" },
        { id: 8, name: "第8节" },
        { id: 9, name: "第9节" },
        { id: 10, name: "第10节" },
        { id: 11, name: "第11节" },
        { id: 12, name: "第12节" },
      ],
      year: "",
      value1: "",
      teachers: [], //教师
      value: null,
      radio: 1,
      date: 1, //日期
      substitute: [], //调课列表
      events: [],
      edit: {
        id: null,
        class_requirements: null,
      },
      substituteCorrect: false,
      editDialogVisible: false,
      adjustment: false,
      btnLoading: false,
      //验证规则
      substituteRules: {
        name: [{ required: true, message: "教师不能为空", trigger: "change" }],
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
      },
      form: {
        teacher_id: null,
        schedule_id: null,
        school_course_id: null,
      },
      teachers1: [],
      listQuery2: {
        class_id: null,
        date: null,
      },
      schedules: [],
      form2: {
        id: null,
        schedule_id: null,
      },
      schools: [],
      time: 0,
    };
  },
  created() {
    this.getSchoolList();
    this.getTime(0);
  },
  methods: {
    batchEdit(item) {
      this.edit.id = item.id;
      this.edit.class_requirements = item.class_requirements;
      this.editDialogVisible = true;
    },
    editData() {
      this.btnLoading = true;
      request({
        url: "/api/teacherend/schedule/oneStore",
        method: "post",
        data: this.edit,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.editDialogVisible = false;
        this.btnLoading = false;
        this.getList();
      });
    },
    change(e) {
      this.value = e;
      this.listQuery.year = e.getFullYear();
      this.listQuery.month = e.getMonth() + 1;
      this.getList();
    },
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: {
          limit: 99999999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
        this.listQuery.school_id = response.data.data[0].id;
      });
    },
    pickerChange(e) {
      this.getTime(0, e);
    },
    getTime(time, chackData) {
      //time = 7下周2  -7上周1   0本周0
      if (time == 1) {
        this.time += 7;
      } else if (time == 2) {
        this.time -= 7;
      } else {
        this.time = 0;
      }
      let date = new Date(); //this.chaoZuoTime
      let currentFirstDate = new Date(date);
      if (chackData) {
        currentFirstDate = new Date(chackData);
      }
      let formatDate = function (date) {
        let year = date.getFullYear(); //年
        let month = date.getMonth() + 1; //月
        let day = date.getDate(); //日;
        let week = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
          date.getDay()
        ];
        //我需要的格式是  （周几 月.日） 这里看你们的需求
        return week + "-" + month + "-" + day + "-" + year;
      };

      let addDate = function (date, n) {
        date.setDate(date.getDate() + n);
        return date;
      };

      let setDate = function (date) {
        let week = date.getDay() - 1; //控制 日开头还是一开头
        date = addDate(date, week * -1);
        currentFirstDate = new Date(date);
        let weekList = [];
        for (let i = 0; i < 7; i++) {
          weekList[i] = formatDate(i == 0 ? date : addDate(date, 1));
        }
        return weekList;
      };
      let thisWeek = setDate(addDate(currentFirstDate, this.time)); //本周
      let list = [];
      thisWeek.forEach((item) => {
        let sp = item.split("-");
        this.year = sp[3] + "年" + sp[1] + "月";
        if (sp[2] < 10) {
          sp[2] = "0" + sp[2];
        }
        list.push({
          name: sp[0],
          data: sp[1] + "·" + sp[2],
          time: sp[3] + "-" + (sp[1] < 10 ? "0" + sp[1] : sp[1]) + "-" + sp[2],
        });
      });
      this.week = list;
      list = [];
      this.listQuery.start_at = this.week[0].time;
      this.listQuery.end_at = this.week[this.week.length - 1].time;
      this.getList();
    },
    //调课代课  确认按钮
    saveData() {
      this.form.school_id = this.listQuery.school_id;
      request({
        url: "/api/teacherend/schedule/replaceCourse",
        method: "post",
        data: this.form,
      }).then((response) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.substituteCorrect = false;
        this.form.teacher_ids = "";
        this.getList();
      });
    },
    saveData1() {
      this.form2.school_id = this.listQuery.school_id;
      request({
        url: "/api/teacherend/schedule/changeCourse",
        method: "post",
        data: this.form2,
      }).then((response) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.adjustment = false;
        this.getList();
      });
    },
    //选择月
    change() {
      this.listQuery2.class_id = this.listQuery.class_id;
      request({
        url: "/api/teacherend/schedule/list",
        method: "get",
        params: this.listQuery2,
      }).then((response) => {
        console.log(response);
        this.schedules = response.data;
        console.log(this.schedules);
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/schedule/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        console.log(response);
        this.events = response.data;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.class_box {
  padding: 10px;
}
.color_tip {
  display: flex;
  align-items: center;
  .daike {
    margin-right: 20px;
    width: 15px;
    height: 15px;
    background: #22dddd;
    margin-left: 20px;
  }
  .tiaoke {
    width: 15px;
    height: 15px;
    background: #44bb44;
    margin-left: 20px;
  }
}
.cell {
  padding: 20px 10px;
  border: 1px solid #dcdfe6;
  position: relative;
  margin-bottom: 10px;
  .row_1 {
    margin-bottom: 5px;
  }
}
.el-icon-error {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 28px;
}
.el-divider--horizontal {
  margin: 10px 0;
}
.add {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-icon-circle-plus-outline {
    font-size: 35px;
  }
}
.calendar {
  .calendar_top {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
  }
  .calendar_centont {
    .calendar_c_top {
      display: flex;
      .calendar_item-top {
        flex: 1;
        text-align: center;
        &:first-child .calendar_item-centont {
          border-left: 1px solid #dcdfe6;
        }
        .calendar_item-centont {
          min-height: 170px;
          // max-height: 485px;
          // height: 500px;
          height: 170px;
          border: 1px solid #dcdfe6;
          // margin-top: 10px;
          // border-left: none;
          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          .calendar_tag {
            color: #1890ff;
            background-color: #e8f4ff;
            border-color: #d1e9ff;
            border-radius: 4px;
            text-align: left;
            padding: 10px;
            margin: 10px;
            p {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}
.broadside {
  height: 170px;
  line-height: 170px;
  border: 1px solid #dcdfe6;
  // margin-top: 0px;
  text-align: center;
}
</style>
